<template>
  <modal name="confirmPopup" transition="pop-out">
    <div class="thanksPopup confirmPopup">

      <div class="spinnerWrapper" v-if="isLoading">
        <Spinner/>
      </div>
      <div
        class="thanksPopup__inner" :class="{'loadingWrapper': isLoading}"
      >
        <div class="thanksPopup__title">{{ title }}</div>
        <div class="confirmPopup__info">
          {{text}}
        </div>
        <div class="buttonsWrapper">
          <div
            class="thanksPopup__button thanksPopup__button--yes js-yes-btn confirmPopup__button"
            @click="hide"
          >
            <span class="text">Cancel</span>
          </div>
          <div
            class="thanksPopup__button thanksPopup__button--yes js-yes-btn confirmPopup__button white-button"
            @click="onClick"
          >
            <span class="text">Confirm</span>
          </div>
        </div>
      </div>

      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('confirmPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import './ConfirmPopup.styl'
  import Spinner from '@/components/common/Spinner/Spinner.vue';

  export default {
    name: 'ConfirmPopup',
    components: {Spinner},
    props: ['title', 'text', 'isLoading'],
    methods: {
      show () {
        this.$modal.show('confirmPopup')
      },
      hide () {
        this.$modal.hide('confirmPopup')
      },
      onClick () {
        this.$emit('confirm');
      },
    },
  }
</script>
