<template>
  <div class="bidDashboard__table">
    <div class="bidDashboard__table-header">
      <div class="bidDashboard__table-cell">№</div>
      <div class="bidDashboard__table-cell bidDashboard__table-cell--date">Date</div>
      <div class="bidDashboard__table-cell">Aircraft Model</div>
      <div class="bidDashboard__table-cell">Aircraft Type</div>
      <div class="bidDashboard__table-cell">From</div>
      <div class="bidDashboard__table-cell">To</div>
      <div class="bidDashboard__table-cell bidDashboard__table-cell--bid">Full Ask Price</div>
      <div class="bidDashboard__table-cell bidDashboard__table-cell--bid">User Bid Amount</div>
      <div class="bidDashboard__table-cell">Flyer's name</div>
      <div class="bidDashboard__table-cell split">Willing To Split With Group</div>
    </div>
    <div class="bidDashboard__table-body js-customScroll">
      <vue-scroll :ops="ops">
        <div
          v-for="item of dataList"
          :key="item.id"
          class="bidDashboard__table-row js-acc-item"
        >
          <div
            class="bidDashboard__table-row-inner js-acc-hover-item"
          >
            <div class="bidDashboard__table-row-header">
              <div class="bidDashboard__table-cell">
                {{item.tailNumber}}
              </div>
              <div class="bidDashboard__table-cell bidDashboard__table-cell--date">{{moment(item.departureDate).format('M/D/YYYY')}}</div>
              <div class="bidDashboard__table-cell">
                {{item.model}}
              </div>
              <div class="bidDashboard__table-cell">
                {{item.aircraftType}}
              </div>
              <div class="bidDashboard__table-cell">
                {{item.departure}}
              </div>
              <div class="bidDashboard__table-cell">
                {{item.arrival}}
              </div>
              <div class="bidDashboard__table-cell bidDashboard__table-cell--bid">
               {{item.exclusiveTotalPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}}
              </div>
              <div class="bidDashboard__table-cell bidDashboard__table-cell--bid">
                {{item.bidValue.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}}
              </div>
              <div class="bidDashboard__table-cell">
                {{item.flyerName}}
              </div>
              <div class="bidDashboard__table-cell split">
                {{item.willingToSplitWithGroup ? 'Yes' : 'No'}}
              </div>
            </div>
            <div class="bidDashboard__table-row-header">
              <div class="bidDashboard__table-cell" v-if="!accepted">
                  <span class="bidDashboard__table-cell-button cancel" @click="(e) => showConfirmationPopup(e, item)">
                    <span class="text">Accept</span>
                  </span>
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
    <ConfirmPopup :text="'Are you sure you want to accept bid?'" :title="'Are you sure?'" @confirm="acceptBid" :isLoading="isLoading"/>
  </div>
</template>
<script>
import moment from 'moment'
import ConfirmPopup from '@/components/common/modals/ConfirmPopop.vue';

export default {
  props: ['dataList', 'accepted'],
  name: 'ProviderAuctionTable',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    bidId: null,
    isLoading: false,
  }),
  methods: {
    moment,
    showConfirmationPopup (e, item) {
      e.stopPropagation()
      this.$modal.show('confirmPopup')
      this.bidId = item.id
    },
    async acceptBid () {
      this.isLoading = true
      await this.$store.dispatch('providerAcceptBid', this.bidId);
      await this.$store.dispatch('getProviderBidList', {accepted: !!this.accepted});
      this.$modal.hide('confirmPopup')
      this.isLoading = false
    },
  },
  components: {
    ConfirmPopup,
  },
}
</script>
