<template>
  <div class="bidDashboard">
    <div class="bidDashboard__header">
      <div class="bidDashboard__title">Auctions</div>
    </div>
    <div class="buttonsWrapper">
      <div class="navWrapper">
        <button name='0' class="bidDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Pending Acceptance</span>
        </button>
        <button name='1' class="bidDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Accepted</span>
        </button>
      </div>
    </div>
    <div class="bidDashboard__content">
      <div class="bidDashboard__content-item js-tabContentItem">
        <ProviderAuctionTable :dataList="pendingAcceptanceBidList" :accepted="+this.tabSelected"/>
      </div>
      <div class="bidDashboard__content-item js-tabContentItem">
        <ProviderAuctionTable :dataList="acceptedBidList" :accepted="+this.tabSelected"/>
      </div>
    </div>
  </div>
</template>

<script>
import {Tabs} from '@/js/plugins/tabs';

import moment from 'moment';
import './Auctions.styl'
import ProviderAuctionTable from '@/components/dashboard/ProviderAuctionTable/ProviderAuctionTable.vue';

export default {
  name: 'Auctions',
  props: ['dashboardType'],
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    tabSelected: '0',
  }),
  computed: {
    pendingAcceptanceBidList () {
      console.log(this.$store.state.providerBids.pendingAcceptanceBidList)
      return this.$store.state.providerBids.pendingAcceptanceBidList
    },
    acceptedBidList () {
      return this.$store.state.providerBids.acceptedBidList
    },
  },
  mounted () {
    this.tabs.addActiveForMouted()
    this.getBidList()
  },
  methods: {
    moment,
    tabActivate (event) {
      this.tabs.addActiveForClick(event.currentTarget)
      this.tabSelected = +event.currentTarget.name

      this.getBidList()
    },
    async getBidList () {
      await this.$store.dispatch('getProviderBidList', {accepted: !!+this.tabSelected});
    },

  },
  components: {
    ProviderAuctionTable,
  },
}</script>
